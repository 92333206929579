import { Container, Grid2 as Grid, Paper } from "@mui/material";
import getConfig from "next/config";
import { size } from "polished";
import { useEffect } from "react";
import { trpc } from "../src/gateways/trpc";
import Link from "../src/ui/Link";

const { publicRuntimeConfig } = getConfig();

export const IndexPage = () => {
  const isProd = publicRuntimeConfig.NX_ENVIRONMENT === "production";
  const queryDochatConfigs = trpc.dochatConfig.all.useQuery(undefined, {
    enabled: false,
    retry: 3,
  });

  useEffect(() => {
    if (!isProd && !queryDochatConfigs.isSuccess) {
      void queryDochatConfigs.refetch();
    }
  }, [queryDochatConfigs, queryDochatConfigs.isSuccess, queryDochatConfigs.refetch, isProd]);

  if (isProd) return <h1>404 not found</h1>;
  return (
    <Container sx={size("100%")}>
      <Grid container direction="column" spacing={1} sx={size("100%")} wrap="nowrap">
        <Grid flexGrow={1}>
          <Paper sx={{ p: 2 }}>
            {(queryDochatConfigs.data ?? []).map(({ id, name }: { id: string; name: string }) => (
              <div key={id}>
                <Link href="/[dochatConfigId]" as={`/${id}`} sx={{ color: "black" }}>
                  {name}
                </Link>
              </div>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default IndexPage;
